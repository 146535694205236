<template>
    <div class="mainbody home">
        <a href="javascript:void(0);" class="scrollT" ref="home"></a>
        <div class="swiper-container swiper-banner">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="b in bannerList" :key="b.id">
                    <div class="imgBg xs-hidden" :style="{'backgroundImage':'url('+b.pic+')'}"></div>
                    <div class="imgBg xs-show" v-if="b.m_pic != ''" :style="{'backgroundImage':'url('+ b.m_pic+')'}"></div>
                    <div class="imgBg xs-show" v-else :style="{'backgroundImage':'url('+ b.pic+')'}"></div>
                    <div class="container">
                        <h2>{{ b.title }}</h2>
                        <div class="box">
                            <div class="info">{{ b.sub_title }}</div>
                            <div class="action" v-if="b.link_url">
                                <a :href="b.link_url">{{ b.link_title }}<i></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="bannerList.length>1" class="swiper-button-next fcc"><i></i></div>
            <div v-if="bannerList.length>1" class="swiper-button-prev fcc"><i></i></div>
        </div>
        <div class="about-box">
			<div class="container">
				<h3 class="wow fadeInLeft">Who is DMEGC Solar</h3>
				<div class="right">
					<div class="info wow fadeInRight">
						<ul>
							<li v-for="(item,index) in infoList" :key="index">
                                {{ item }}
                            </li>
						</ul>
						<div class="pic">
							<img src="../../assets/images/about.png" alt="">
						</div>
					</div>
					<div class="num-list">
						<div class="step wow fadeInUp"
                            v-for="(item,index) in dataList"
                            :key="index"
                            :data-wow-delay="(index+1)/5+'s'"
                            >
							<div class="num"><span>{{ item.value }}</span>{{ item.units }}</div>
							<p>{{ item.title }}</p>
						</div>
					</div>
                    <router-link to="/about" class="read-more wow fadeInUp">View More<i></i></router-link>
				</div>
			</div>
		</div>
        <div class="solution-box">
			<div class="container">
				<div class="top">
					<h3 class="wow fadeInLeft">Solutions</h3>
					<div class="right wow fadeInRight">
						<div class="info">
                            As a Tier 1 solar manufacturer, DMEGC Solar's full-service of residential, C&I and utility solutions offer unprecendented value, reliability, and support to project partners.
                        </div>
					</div>
				</div>
                <div class="m-arrod wow fadeInRight" v-if="isMobile">
                    <div class="swiper-container swiper-accord">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"
                                v-for="aItem in solutionList"
                                :key="aItem.id">
                                <div class="imgBg" :style="{'backgroundImage':'url('+aItem.rmd_pic+')'}">
                                    <div class="box">
                                        <div class="img">
                                            <img :src="aItem.icon" alt="">
                                        </div>
                                        <div class="name">{{ aItem.title }}</div>
                                    </div>
                                </div>
                                <div class="txt">
                                    {{ aItem.intro }}
                                </div>
                                <div class="links">
                                    <router-link :to="`/solution?id=${aItem.id}`">See where we apply <i></i></router-link>
                                    <router-link to="/product">Learn about our products <i></i></router-link>
                                    <router-link to="/download">Download Center <i></i></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
				<div class="accord-list" v-else>
					<div class="item motion wow fadeInRight" 
                        :class="sIndex=== index ? 'active':''" 
                        v-for="(aItem,index) in solutionList"
                        :key="aItem.id"
                        :data-wow-delay="(index + 1)/5 +'s'"
                        @click="changeAccord(index)">
                        <div class="imgBg" :style="{'backgroundImage':'url('+aItem.rmd_pic+')'}"></div>
						<div class="main motion">
							<div class="box">
                                <div class="img">
								<img :src="aItem.icon" alt="">
                                </div>
                                <div class="name">{{ aItem.title }}</div>
                                <div class="txt">
                                    {{ aItem.intro }}
                                </div>
                                <div class="links">
                                    <router-link :to="`/solution?id=${aItem.id}`">See where we apply <i></i></router-link>
                                    <router-link to="/product">Learn about our products <i></i></router-link>
                                    <router-link to="/download">Download Center <i></i></router-link>
                                </div>
                            </div>
						</div>
					</div>

				</div>
			</div>
		</div>
        <div class="customer-box">
			<div class="container">
				<h3 class="wow fadeInLeft">Successful Cases</h3>
				<div class="right wow fadeInRight">
					<div class="swiper-container swiper-case">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" 
                                v-for="cItem in caseList"
                                :key="cItem.id">
                                <div class="box">
                                    <div class="main">
                                        <div class="name">{{ cItem.title }}</div>
                                        <div class="txt">
                                            {{ cItem.detail }}
                                        </div>
                                    </div>
                                    <div class="imgBg motion" :style="{'backgroundImage':'url('+cItem.pic+')'}"></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="caseList.length>4" class="swiper-button-next fcc"><i></i></div>
                        <div v-if="caseList.length>4" class="swiper-button-prev fcc"><i></i></div>
                        <div class="swiper-pagination"></div>
                    </div>
                    <router-link to="/case" class="read-more wow fadeInUp">Read More<i></i></router-link>
				</div>
			</div>
		</div>
        <div class="product-box">
			<div class="container">
				<h3 class="wow fadeInLeft">Products</h3>
				<div class="right">
                    <ul class="nav wow fadeInRight">
                        <li v-for="(item,index) in hpList"
                            :key="index"
                            :class="pIndex === index ? 'active':''"
                            @click="changePro(index)">
                            {{ item.title }}
                        </li>
                    </ul>
					<div class="tabs wow fadeInUp" v-if="hpList.length > 0">
                        <div class="swiper-container swiper-product">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" 
                                    v-for="item in hpList[pIndex].sList"
                                    :key="item.id">
                                    <div class="item-flex">
                                        <div class="info">
                                            <div class="name">{{ item.title }}</div>
                                            <div class="tips">{{ item.sub_title }}</div>
                                            <div class="list">
                                                <div class="step"
                                                    v-for="(subItem,index) in item.parsList"
                                                    :key="index">
                                                    <p>{{ subItem.title }}</p>
                                                    <div class="num"><span>{{ subItem.value }}</span></div>
                                                </div>
                                            </div>
                                            <a :href="item.fileInfo.files" class="read-more" target="_blank">Read More<i></i></a>
                                        </div>
                                        <div class="img">
                                            <img :src="item.pic" alt="">
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
					</div>
				</div>
			</div>
		</div>
        <div class="news-box">
			<div class="container">
				<h3 class="wow fadeInLeft">Latest news</h3>
				<div class="right">
					<ul class="clearfix">
						<li class="wow fadeInRight" 
                            :data-wow-delay="(index+1)/5 + 's'"
                            v-for="(item,index) in newsList"
                            :key="item.id">
                            <router-link :to="`/newsdet?id=${item.id}`">
                                <div class="name lineTwo">{{ item.title }}</div>
                                <div class="time">{{ item.add_date }}</div>
                                <div class="txt" v-html="item.detail.replace(/<[^>]+>/g, '').substr(0,120)+'...'"></div>
                                <div class="more">Read More<i></i></div>
                            </router-link>
                        </li>
					</ul>
                    <router-link to="/news" class="read-more wow fadeInUp">Read More<i></i></router-link>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    import Swiper from 'swiper'
    import 'swiper/css/swiper.min.css'
    import { WOW } from 'wowjs'
    import { getHomeAboutInfo, getBannerList, getNewsList, getSolutionList, getCaseList, getHomeRmdproList } from '../../request/api'
    import _isMobile from '../../utils'
    export default {
        name:'Home',
        metaInfo:{
            title:"DMEGC Solar",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        data(){
            return {
                infoList:[],
                dataList:[],
                bannerList:[],
                solutionList: [],
                sIndex: 0,
                caseList:[],
                hpList:[],
                pIndex:0,
                newsList:[],
                isMobile:false
            }
        },
        mounted(){
            this.isMobile = _isMobile();

            getBannerList({cate:'home'}).then( res => {

                this.bannerList = res.data
            })

            getHomeAboutInfo().then( res => {

                this.infoList = res.data.info.split('|*|')
                this.dataList = res.data.dataList
            })

            getSolutionList({rmd:'on',rows:3}).then( res => {

                this.solutionList = res.data
            })

            getCaseList({rmd:'on',rows:12}).then( res => {

                this.caseList = res.data
            })

            getHomeRmdproList().then( res => {

                res.data.map( item => {

                    this.hpList.push({
                        title: item.name,
                        sList: item.proList
                    })
                })
                new Swiper('.swiper-product',{
                    slidesPerView: 1,
                    spaceBetween: 10,
                    loop: true,
                    observer: true,
                    observeParents:true,
                    pagination: {
                        el: '.swiper-product .swiper-pagination',
                    },
                });
            })

            getNewsList({rmd:'on',rows:3}).then( res => {

                this.newsList = res.data.newsList
                this.$nextTick(() => {
                    // 在dom渲染完后,再执行动画
                    const wow = new WOW({
                        animateClass: 'animated',
                        offset: 100
                    })
                    wow.init();
                    this.$refs.home.scrollIntoView();

                    if(this.isMobile){

                        new Swiper('.swiper-accord',{
                            slidesPerView: 1,
                            spaceBetween: 10,
                            observer: true,
                            pagination: {
                                el: '.swiper-accord .swiper-pagination',
                            },
                        });

                        new Swiper('.swiper-case',{
                            slidesPerView: 1,
                            spaceBetween: 10,
                            observer: true,
                            navigation: {
                                nextEl: '.swiper-case .swiper-button-next',
                                prevEl: '.swiper-case .swiper-button-prev',
                            },
                            pagination: {
                                el: '.swiper-case .swiper-pagination',
                            },
                        });
                    } else {

                        new Swiper('.swiper-case',{
                            slidesPerView: 2,
                            spaceBetween: 2,
                            slidesPerColumnFill: 'row',
                            slidesPerColumn: 2,
                            observer: true,
                            navigation: {
                                nextEl: '.swiper-case .swiper-button-next',
                                prevEl: '.swiper-case .swiper-button-prev',
                            },
                            pagination: {
                                el: '.swiper-case .swiper-pagination',
                            },
                        });
                    }

                    new Swiper('.swiper-banner',{
                        slidesPerView: 1,
                        speed:500,
                        autoplay: {
                            delay: 6000,
                            stopOnLastSlide: false,
                            disableOnInteraction: true,
                        },
                        observer: true,
                        loop: true,
                        navigation: {
                            nextEl: '.swiper-banner .swiper-button-next',
                            prevEl: '.swiper-banner .swiper-button-prev',
                        },
                    });
                    new Swiper('.swiper-product',{
                        slidesPerView: 1,
                        speed:500,
                        autoplay: {
                            delay: 6000,
                            stopOnLastSlide: false,
                            disableOnInteraction: true,
                        },
                        observer: true,
                        observeParents:true,
                        pagination: {
                            el: '.swiper-product .swiper-pagination',
                        },
                    });
                });
            })
        },
        methods: {
            changeAccord( num ) {

                this.sIndex = num
            },
            changePro( num ) {

                this.pIndex = num
            }
        }
    }
</script>

<style lang="scss" scoped>
.home{
    position: relative;
    .swiper-banner{
        position: relative;
        .imgBg{
            display: block;
            width: 100%;
            height: 35.4vw;
        }
        .container{
            display: flex;
            box-sizing: border-box;
            padding: 2.0833vw 0;
            height: 100%;
            left: 9.375vw;
            position: absolute;
            top: 0;
            flex-direction: column;
            justify-content: space-between;
        }
        h2{
            font-size: 2.7083vw;
            line-height: 3.0208vw;
            color: #fff;
            font-weight: 300;
            width: 28.125vw;
        }
        .info{
            font-size: 1.1458vw;
            line-height: 1.5625vw;
            color: rgba(255, 255, 255, 0.8);
            width: 28.125vw;
        }
        .action{
            display: flex;
            margin-top: 1.6667vw;
            a{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 13.5417vw;
                height: 2.6042vw;
                box-sizing: border-box;
                border:1px solid #fff;
                padding: 0 1.4583vw;
                font-size: 0.8333vw;
                color: #fff;
                margin-right: 1.0417vw;
                i{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/arrowR.png') no-repeat;
                    background-size: cover;
                }
                &:hover{
                    border-color: #E60011;
                    background: #E60011;
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev{
            left: 3.125vw;
            margin: -1.3021vw 0 0 0;
            width: 2.6042vw;
            height: 2.6042vw;
            background: #232323;
            &::after{
                display: none;
            }
            i{
                display: block;
                width: 1.25vw;
                height: 1.25vw;
                background: url('../../assets/images/arrowR.png') no-repeat;
                background-size: cover;
                transform: rotate(180deg);
            }
            &:hover{
                background: #E60011;
            }
        }
        .swiper-button-next{
            left: auto;
            right: 3.125vw;
            i{
                transform: rotate(0deg);
            }
        }
    }
    .about-box{
        background: #232323;
        padding: 4.1667vw 0 5.7292vw;
        h3{
            color: #fff;
        }
        .container{
            display: flex;
            justify-content: space-between;
        }
        .info{
            display: flex;
            justify-content: space-between;
            li{
                font-size: 0.9896vw;
                line-height: 1.875vw;
                color: #fff;
                font-weight: 300;
                padding-left: 1.1458vw;
                position: relative;
                &::before{
                    content: '';
                    width: 0.3125vw;
                    height: 0.3125vw;
                    background: #E60011;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0.7813vw;
                }
            }
            .pic{
                width: 13.0208vw;
            }
        }
        .num-list{
            margin-top: 3.9583vw;
            padding-top: 4.1667vw;
            border-top: 1px solid rgba(196, 196, 196, 0.3);
            justify-content: space-between;
            .step:nth-child(2n){
                margin: 0;
            }
        }
        .read-more {
            margin-top: 5.3333vw;
        }
    }
    .solution-box{
        padding: 4.1667vw 0;
        background: #F2F4F8;
        .top{
            display: flex;
            justify-content: space-between;
        }
        .info{
            font-size: 1.5625vw;
            line-height: 1.9792vw;
            color: rgba(0, 0, 0, 0.6);
            width: 46.3542vw;
        }
        .accord-list{
            display: flex;
            justify-content: space-between;
            margin-top: 3.3854vw;
            .item{
                width: 10.4167vw;
                height: 29.1667vw;
                position: relative;
                overflow: hidden;
                cursor: pointer;
            }
            .imgBg{
                position: absolute;
                left: 0;
                top: 0;
                width: 59.375vw;
                height: 29.1667vw;
                &::before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.26);
                    z-index: 5;
                }
            }
            .main{
                position: relative;
                z-index: 10;
                box-sizing: border-box;
                height: 100%;
                display: flex;
                align-items: center;
            }
            .box{
                width: 100%;
            }
            .img{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.9063vw;
                height: 3.75vw;
                margin: 0 auto;
                img{
                    display: block;
                    max-width: 100%;
                    max-height: 3.75vw;
                }
            }
            .name{
                font-size: 1.25vw;
                line-height: 2.5vw;
                color: #fff;
                text-align: center;
                margin-top: 0.8333vw;
            }
            .txt{
                font-size: 0.8333vw;
                line-height: 1.25vw;
                width: 23.75vw;
                margin-top: 1.0417vw;
                color: #fff;
                font-weight: 300;
                display: none;
            }
            .links{
                margin-top: 2.5vw;
                display: none;
                a{
                    display: flex;
                    font-size: 0.8333vw;
                    line-height: 1.7708vw;
                    color: #fff;
                    font-weight: 300;
                    align-items: center;
                    i{
                        display: block;
                        width: 1.25vw;
                        height: 1.25vw;
                        background: url('../../assets/images/arrowR.png') no-repeat;
                        background-size: cover;
                        margin-left: 0.7292vw;
                    }
                }
            }
            .active{
                width: 59.375vw;
                //width: 48.8583vw;
                .main{
                    padding: 0 4.8333vw;
                }
                .img{
                    margin-left: 0;
                }
                .name{
                    font-weight: bold;
                    text-align: left;
                }
                .txt,
                .links{
                    display: block;
                }
            }
        }
    }
    .customer-box{
        padding: 4.1667vw 0;
        background: url('../../assets/images/customer_bg.png') repeat;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .swiper-case{
            padding-bottom: 4.1667vw;
            height: 21.1458vw;
            .swiper-slide{
                height: 10.5208vw;
                background: #F2F4F8;
                .box{
                    position: relative;
                    overflow: hidden;
                    height: 10.5208vw;
                    &::before{
                        content: '';
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        border:3px solid #E60011;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 16;
                        opacity: 0;
                        transition:all 0.5s ease-in-out;
                        -webkit-transition:all 0.5s ease-in-out;
                        -moz-transition:all 0.5s ease-in-out;
                        -o-transition:all 0.5s ease-in-out;
                    }
                    &:hover{
                        .imgBg{
                            transform: scale(1.1,1.1);
                        }
                        .name,
                        .txt{
                            color: #fff;
                        }
                        &::before{
                            opacity: 1;
                        }
                    }
                }
                .main{
                    position: relative;
                    z-index: 10;
                    padding: 1.0417vw;
                }
                .name{
                    font-size: 1.5625vw;
                    line-height: 2.5vw;
                    color: #fff;
                }
                .txt{
                    font-size: 0.9375vw;
                    line-height: 1.3542vw;
                    margin-top: 1.0417vw;
                    color: #fff;
                    font-weight: 300;
                }
                .imgBg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .swiper-button-next,
            .swiper-button-prev{
                left: 0;
                top: auto;
                margin: 0;
                bottom: 0;
                width: 2.6042vw;
                height: 2.6042vw;
                background: #232323;
                &::after{
                    display: none;
                }
                i{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/arrowR.png') no-repeat;
                    background-size: cover;
                    transform: rotate(180deg);
                }
                &:hover{
                    background: #E60011;
                }
            }
            .swiper-button-next{
                margin-left: calc(2.6042vw + 2px);
                i{
                    transform: rotate(0deg);
                }
            }
            .swiper-pagination{
                display: none;
            }
        }
    }
    .product-box{
        padding: 4.1667vw 0 4.6875vw;
        background: #F2F4F8;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .nav{
            display: flex;
            li{
                font-size: 0.9896vw;
                line-height: 2.5vw;
                color: rgba(0, 0, 0, 0.6);
                margin-right: 2.6042vw;
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .tabs{
            margin-top: 3.6458vw;
        }
        .item-flex{
            display: flex;
            justify-content: space-between;
        }
        .info{
            width: 28vw;
            .name{
                font-size: 1.6667vw;
                line-height: 1.9792vw;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.8);
            }
            .tips{
                font-size: 1.25vw;
                line-height: 1.5625vw;
                color: rgba(0, 0, 0, 0.8);
                font-weight: 300;
            }
            .list{
                display: flex;
                flex-wrap: wrap;
                margin-top: 3.2292vw;
                padding-bottom: 3.125vw;
                .step{
                    margin-right: 1.875vw;
                    padding-right: 1.875vw;
                    border-right: 1px solid rgba(0, 0, 0, 0.8);
                    margin-bottom: 1.25vw;
                    &:nth-child(3),
                    &:last-child{
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
                p{
                    font-size: 0.9375vw;
                    line-height: 1.1458vw;
                    color: rgba(0, 0, 0, 0.8);
                }
                .num{
                    margin-top: 0.625vw;
                    font-size: 1.4583vw;
                    line-height: 1.7708vw;
                    span{
                        font-weight: bold;
                    }
                }
            }
        }
        .img{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28.125vw;
            height: 26.5625vw;
            img{
                display: block;
                max-width: 100%;
                max-height: 26.5625vw;
            }
        }
        .swiper-product{
            .swiper-pagination-bullets{
                width: 28.125vw;
                left: auto;
                right: 0;
                bottom: 0;
                /deep/.swiper-pagination-bullet{
                    background: #AFBAC7;
                    margin: 0 0.4167vw;
                    border-radius: 100%;
                }
                /deep/.swiper-pagination-bullet-active{
                    background: #E60011;
                }
            }
        }
    }
    .news-box{
        padding: 4.1667vw 0;
        .container{
            display: flex;
            justify-content: space-between;
        }
        ul{
            display: flex;
            justify-content: space-between;
            li{
                width: 18.75vw;
                &:hover{
                    .name{
                        color: #E60011;
                    }
                }
            }
            .name{
                font-size: 1.3542vw;
                line-height: 1.7708vw;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.8);
            }
            .time{
                font-size: 0.8333vw;
                line-height: 1.0417vw;
                color: rgba(0, 0, 0, 0.8);
                font-weight: 600;
                font-style: italic;
                margin-top: 0.625vw;
            }
            .txt{
                font-size: 0.7813vw;
                line-height: 1.1979vw;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 2.0833vw;
            }
            .more{
                display: flex;
                height: 1.7708vw;
                align-items: center;
                font-size: 14px;
                color: #E60011;
                margin-top: 1.7708vw;
                i{
                    display: block;
                    width: 0.8333vw;
                    height: 0.8333vw;
                    background: url('../../assets/images/arrowRR.png') no-repeat;
                    background-size: cover;
                    margin-left: 0.9375vw;
                }
            }
        }
        .read-more{
            margin-top: 1.875vw;
        }
    }
}
@media screen and (max-width: 750px) {
    .home{
        .swiper-banner{
            .imgBg{
                height: 6rem;
            }
            .container{
                width: 92%;
                left: 4%;
                padding: .75rem 0 1.05rem;
            }
            h2{
                font-size: .52rem;
                line-height: .6rem;
                width: 6rem;
            }
            .info{
                font-size: .3rem;
                line-height: .42rem;
                width: 6rem;
            }
            .action{
                margin-top: .4rem;
                a{
                    width: 2.92rem;
                    height: .7rem;
                    margin-right: .2rem;
                    padding: 0 .3rem;
                    font-size: .22rem;
                    i{
                        width: .32rem;
                        height: .32rem;
                    }
                    &:last-child{
                        padding: 0 .16rem;
                    }
                }
            }
            .swiper-button-next,
            .swiper-button-prev{
                display: none;
            }
        }
        .about-box{
            padding: .6rem 0 .8rem;
            .container{
                display: block;
            }
            .info{
                flex-direction: column-reverse;
                .pic{
                    width: 5.26rem;
                    margin: 0 auto .45rem;
                }
                li{
                    font-size: .26rem;
                    line-height: .4rem;
                    margin-bottom: .2rem;
                    padding-left: .32rem;
                    &::before{
                        width: .08rem;
                        height: .08rem;
                        top: .16rem;
                    }
                }
            }
            .num-list{
                margin-top: .7rem;
                padding-top: .7rem;
                p{
                    width: 1.8rem !important;
                }
            }
            .read-more{
                margin-top: .5rem;
            }
        }
        .customer-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .swiper-case{
                padding-bottom: .5rem;
                height: 2.5rem;
                .swiper-slide{
                    height: 2.5rem;
                    .box{
                        height: 2.5rem;
                        &::before{
                            border-width: 2px;
                        }
                    }
                    .main{
                        padding: .4rem;
                    }
                    .name{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                    .txt{
                        font-size: .26rem;
                        line-height: .44rem;
                        margin-top: .1rem;
                    }
                }
                .swiper-button-next,
                .swiper-button-prev{
                    display: none;
                }
                /deep/.swiper-pagination{
                    display: flex;
                    justify-content: center;
                    bottom: 0;
                    .swiper-pagination-bullet{
                        width: .12rem;
                        height: .12rem;
                        margin: 0 .12rem;
                        background: #AFBAC7;
                    }
                    .swiper-pagination-bullet-active{
                        background: #E60011;
                    }
                }
            }
        }
        .solution-box{
            padding:.8rem 0;
            .top{
                display: block;
            }
            .info{
                font-size: .26rem;
                line-height: .44rem;
                width: 100%;
            }
            .swiper-accord{
                margin-top: .5rem;
                .imgBg{
                    position: relative;
                    height: 3.68rem;
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.26);
                        z-index: 5;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .box{
                        position: absolute;
                        left: 0;
                        top:1.06rem;
                        width: 100%;
                        z-index: 10;
                    }
                    .img{
                        height: .96rem;
                        img{
                            display: block;
                            height: .96rem;
                            margin: 0 auto;
                        }
                    }
                    .name{
                        text-align: center;
                        font-size: .26rem;
                        line-height: .4rem;
                        font-weight: bold;
                        color: #fff;
                        margin-top: .2rem;
                    }
                }
                .txt{
                    font-size: .26rem;
                    line-height: .44rem;
                    color: rgba(0, 0, 0, 0.6);
                    margin-top: .4rem;
                }
                .links{
                    margin-top: .48rem;
                    a{
                        display: flex;
                        align-items: center;
                        font-size: .24rem;
                        line-height: .36rem;
                        margin-bottom: .2rem;
                        color: rgba(0, 0, 0, 0.6);
                        i{
                            display: block;
                            width: .36rem;
                            height: .36rem;
                            margin-left: .14rem;
                            background: url('../../assets/images/arrowRB.png') no-repeat;
                            background-size: cover;
                        }
                    }
                }
                /deep/.swiper-pagination{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    top: 3.2rem;
                    .swiper-pagination-bullet{
                        width: .12rem;
                        height: .12rem;
                        margin: 0 .12rem;
                        background: #AFBAC7;
                    }
                    .swiper-pagination-bullet-active{
                        background: #E60011;
                    }
                }
            }
        }
        .product-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .nav{
                li{
                    margin-right: .5rem;
                    font-size: .28rem;
                    line-height: .8rem;
                }
            }
            .item-flex{
                flex-direction: column-reverse;
            }
            .img{
                width: 5.24rem;
                height: 4.96rem;
                margin: 0 auto;
                img{
                    max-height: 4.96rem;
                }
            }
            .info{
                width: 100%;
                margin-top: .72rem;
                .name{
                    font-size: .38rem;
                    line-height: .58rem;
                }
                .tips{
                    font-size: .26rem;
                    line-height: .36rem;
                }
                .list{
                    margin-top: .32rem;
                    padding-bottom: .16rem;
                    flex-wrap: wrap;
                    .step{
                        margin-bottom: .3rem;
                        margin-right: .36rem;
                        padding-right: .36rem;
                        &:nth-child(3),
                        &:last-child{
                            padding-right: 0;
                            margin-right: 0;
                            border-right: none;
                        }
                    }
                    p{
                        font-size: .24rem;
                        line-height: .34rem;
                    }
                    .num{
                        font-size: .34rem;
                        left: .44rem;
                        margin-top: .1rem;
                        span{
                            font-weight: normal;
                            font-size: .34rem;
                        }
                    }
                }
            }
            .swiper-product{
                .swiper-pagination-bullets{
                    width: 100%;
                    left: auto;
                    right: 0;
                    bottom: auto;
                    top: 5.2rem;
                    /deep/.swiper-pagination-bullet{
                        width: .12rem;
                        height: .12rem;
                        margin: 0 .12rem;
                    }
                }
            }
        }
        .news-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            ul{
                display: block;
                li{
                    width: 100%;
                    margin-bottom: .4rem;
                }
                .name{
                    font-size: .32rem;
                    line-height: .42rem;
                    font-weight: normal;
                }
                .time{
                    font-size: .24rem;
                    margin-top: .1rem;
                    line-height: .32rem;
                    font-weight: normal;
                }
                .txt{
                    font-size: .24rem;
                    line-height: .36rem;
                    margin-top: .2rem;
                }
                .more{
                    display: none;
                }
            }
        }
    }
}
</style>